import React, { useEffect } from "react";
import Form from "../../Form";
import GoogleMapPoint from "../../GoogleMapPoint";
import CarsChargeTable from "../Common/CarsChargeTable";
import PopularOutstations from '../../PopularOutstations'

const ChennaitoKumbakonam = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <iframe
        width="100%"
        height="500"
        frameborder="0"
        src="https://www.google.com/maps/embed/v1/directions?key=AIzaSyBj7X_vm02csFhFln1d0YPu72VBkccy8b8&origin=Chennai,Tamil+Nadu&destination=Trichy,Tamil+Nadu"
        allowfullscreen
      ></iframe> */}
      <GoogleMapPoint origin={{ lat: 13.0827, lng: 80.2707 }} destination={{ lat: 10.9602, lng: 79.3845 }} />
      <div className="form_bg relative bg-fixed flex justify-center px-3">
        <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
          <Form />
        </div>
      </div>


      <div className=" container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
        <h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
          CONVENIENT Chennai to Kumbakonam  TAXI SERVICE: YOUR SEAMLESS TRAVEL SOLUTION
        </ h3>
        <p className=" pt-2">
          Looking for a convenient and reliable taxi service for your journey from Chennai to Kumbakonam?
          Your search ends here! Our taxi service offers a seamless and hassle-free travel solution tailored
          to meet your needs.

        </p>

        <h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase mt-5">
          Discovering the Outstation Taxi Advantage:
        </h3>
        <p className=" pt-2">
          Choose from our fleet of comfortable and well-maintained cars, including sedans,
          SUVs, and spacious vehicles suitable for groups. Enjoy a comfortable ride throughout your journey.

        </p>

        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
          Doorstep Pickup and Drop-off:
        </h3>
        <p className=" pt-2">
          Punctuality is our commitment. Our drivers will arrive promptly at your specified location in
          Chennai and ensure you reach your destination in Kumbakonam on time, without any unnecessary delays.

        </p>

        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
          Comfortable and Well-Maintained Vehicles:
        </h3>
        <p className=" pt-2">
          All the vehicles provided by Outstation Taxi are carefully selected
          and regularly maintained to ensure maximum comfort and safety. You can
          choose from a range of options, including sedans, SUVs, and even
          luxury cars, depending on your preferences and requirements.
        </p>

        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
          Professional and Reliable Drivers:
        </h3>
        <p className=" pt-2">
          Our experienced and courteous drivers are trained to prioritize your safety and comfort.
          They are familiar with the best routes between Chennai and Kumbakonam, ensuring a smooth and
          efficient travel experience.

        </p>

        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
          TRANSPARENT PRICING:
        </h3>
        <p className=" pt-2">
          We believe in transparent pricing with no hidden charges.
          You'll receive a fair and upfront fare estimate before booking your ride,
          allowing you to plan your travel budget effectively.

        </p>

        <div className=" my-4 sm:my-6 md:my-10">
          <CarsChargeTable />
        </div>

        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
          24/7 Customer Support:
        </h3>
        <p className=" pt-2">
          Whether you need a taxi during the day or night, our service operates 24/7 to accommodate
          your travel schedule. You can rely on us for a comfortable and reliable ride at any time.

        </p>

        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
          The Enchanting Journey from Chennai to Kumbakonam:
        </h3>
        <p className=" pt-2">
          Don't let transportation hassles disrupt your plans. Choose our Chennai to Kumbakonam taxi service for a
          seamless and enjoyable travel experience. Sit back, relax, and enjoy the journey as we take you to your
          destination safely and comfortably.

        </p>

        <p className=" pt-5">
          Contact us today to book your taxi and experience hassle-free travel with exceptional service.
          We look forward to serving you and ensuring your trip is a memorable one for all the right reasons.

        </p>
      </div>
      < PopularOutstations />
    </div>
  );
};

export default ChennaitoKumbakonam;
