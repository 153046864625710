import React, { useEffect } from 'react'
import GoogleMapPoint from '../../GoogleMapPoint'
import Form from '../../Form'
import CarsChargeTable from '../Common/CarsChargeTable'
import PopularOutstations from '../../PopularOutstations'

const CoimbatoretoBangalore = () => {
      useEffect(() => {
            window.scrollTo(0, 0);
      }, []);
      return (
            <div>
                  {/* <iframe
      width="100%"
      height="500"
      frameborder="0"
      src="https://www.google.com/maps/embed/v1/directions?key=AIzaSyBj7X_vm02csFhFln1d0YPu72VBkccy8b8&origin=Chennai,Tamil+Nadu&destination=Bengaluru,Karnataka"
      allowfullscreen
    ></iframe> */}
                  <GoogleMapPoint origin={{ lat: 11.0168, lng: 76.9558 }} destination={{ lat: 12.9716, lng: 77.5946 }} />

                  <div className="form_bg relative bg-fixed flex justify-center px-3">
                        <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
                              <Form />
                        </div>
                  </div>


                  <div className=" container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
                        <h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
                              THE ULTIMATE ROAD TRIP: COIMBATORE TO BANGALORE EXCURSION WITH OUTSTATION TAXI
                        </h3>
                        <p className=" pt-2">
                              Embarking on a journey from the vibrant city of Coimbatore to the charming town of Bangalore is a delightful experience that unveils the cultural richness and natural beauty of Tamil Nadu. As you set foot on this exciting adventure, make the wise choice of using Outstation Taxi, a reliable and efficient transportation service that guarantees a comfortable and hassle-free trip. Whether you are a local resident or a traveler from afar, Outstation Taxi is the perfect companion to ensure a memorable and enjoyable journey across the state.

                        </p>

                        <h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase mt-5">
                              DISCOVERING THE OUTSTATION TAXI ADVANTAGE:
                        </h3>
                        <p className=" pt-2">
                              Outstation Taxi is a leading car rental service in Tamil Nadu, offering an array of modern, well-maintained vehicles and experienced drivers. With their commitment to customer satisfaction, they have gained a reputation for providing seamless travel experiences. Here are some key advantages of choosing Outstation Taxi for your Coimbatore to Bangalore trip:

                        </p>

                        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
                              DOORSTEP PICKUP AND DROP-OFF:
                        </h3>
                        <p className=" pt-2">
                              Outstation Taxi understands the value of convenience, and hence, they offer doorstep pickup and drop-off services. Whether you're starting your journey from Coimbatore Central or planning to board the taxi near the airport, Outstation Taxi ensures that your travel begins and ends right at your desired location.

                        </p>

                        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
                              COMFORTABLE AND WELL-MAINTAINED VEHICLES:
                        </h3>
                        <p className=" pt-2">
                              All the vehicles provided by Outstation Taxi are carefully selected and regularly maintained to ensure maximum comfort and safety. You can choose from a range of options, including sedans, SUVs, and even luxury cars, depending on your preferences and requirements.

                        </p>

                        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
                              PROFESSIONAL AND RELIABLE DRIVERS:
                        </h3>
                        <p className=" pt-2">
                              Outstation Taxi takes pride in its team of professional and courteous drivers who possess excellent knowledge of the routes and destinations. Their commitment to providing a smooth and enjoyable ride is reflected in their punctuality and attentive service, ensuring you have a stress-free journey.

                        </p>

                        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
                              TRANSPARENT PRICING:
                        </h3>
                        <p className=" pt-2">
                              One of the major concerns while opting for a taxi service is hidden costs. Outstation Taxi eliminates this worry by offering transparent pricing. The fare is determined upfront, taking into account the distance, duration, and vehicle type, allowing you to plan your travel budget efficiently.

                        </p>

                        <div className=" my-4 sm:my-6 md:my-10">
                              <CarsChargeTable />
                        </div>

                        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
                              24/7 CUSTOMER SUPPORT:
                        </h3>
                        <p className=" pt-2">
                              To cater to any unforeseen circumstances or address any queries or concerns, Outstation Taxi provides round-the-clock customer support. You can reach out to their dedicated team for assistance or guidance at any time during your journey.

                        </p>

                        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
                              THE ENCHANTING JOURNEY FROM COIMBATORE TO BANGALORE:
                        </h3>
                        <p className=" pt-2">
                              As you embark on your journey from Coimbatore to Bangalore with Outstation Taxi, you'll be treated to picturesque landscapes, iconic landmarks, and the rich cultural heritage of Tamil Nadu. Here are a few noteworthy attractions you can explore along the way:

                        </p>

                        <p className=" pt-5">
                              Mahabalipuram : Make a pit stop at the UNESCO World Heritage Site of Mahabalipuram, renowned for its ancient rock-cut temples and stunning coastal views. Admire the intricately carved structures such as the Shore Temple, Pancha Rathas, and Arjuna's Penance.

                        </p>
                  </div>
                  < PopularOutstations />
            </div>
      )
}

export default CoimbatoretoBangalore
