const telegramApi = '6371145161:AAEr8jz6iD7ZOW_4eSiJcDLR9HYpeGXfMuY'; // Replace with your actual backend URL
const telegramChatId=5235437711;
const netbixTelegramApi = '6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo'; // Replace with your actual backend URL
const netbixTelegramChatId=1113684027;

// mobile number
const customerMobileNumber = 9443698657;


const front_url = "https://www.idroptaxis.com/";
export { telegramApi, telegramChatId, netbixTelegramApi, netbixTelegramChatId, customerMobileNumber, front_url };